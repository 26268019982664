<template>
  <div class="container">
    <div v-if="step == 1">
      <div id="nologged" v-if="noLogged">
        {{ $gettext('Questa pagina è accessibile solo agli utenti loggati.') }}
        <br />
        <br />
        {{ $gettext('Per favore effettua il login') }}
      </div>
      <div id="nologged" v-if="!noLogged">
        <div id="nomediv">
          <custom-input
            placeholder="Inserisci il tuo nome"
            label="Nome"
            :value="dati.first_name"
            type="text"
            @input="(val) => (dati.first_name = val)"
          />
        </div>
        <div id="cognomediv">
          <custom-input
            :placeholder='$gettext("Inserisci il tuo cognome")'
            :label='$gettext("Cognome")'
            :value="dati.last_name"
            type="text"
            @input="(val) => (dati.last_name = val)"
          />
        </div>
        <div id="bio">
          <custom-textarea
            :label='$gettext("Inserisci una breve descrizione di te")'
            name="descrizione"
            :value="dati.bio"
            @input="(val) => (dati.bio = val)"
          ></custom-textarea>
        </div>
        <div id="language" class="field" style="display: flex;">
          <div class="label">{{ $gettext('Scegli la lingua') }}</div>
          <div><select v-model="dati.language">
  <option disabled value="">{{$gettext("Please select one")}}</option>
  <option :checked="dati.language == 'it'" value="it">{{$gettext("Italiano")}}</option>
  <option :checked="dati.language == 'en'" value="en">{{$gettext("Inglese")}}</option>
</select></div>
        </div>
        <input
          type="file"
          name="img"
          id="input_img"
          @change="loadImage"
          multiple
          style="display: none"
        />
        <div id="avatar_div">
          <div id="field-container">
            <div id="label">AVATAR</div>
          </div>
          <div id="img">
            <img v-if="avatarSrc != false" :src="avatarSrc" />
            <img v-if="avatarSrc == false" src="../../assets/icons/user.svg" />
          </div>
          <div class="button">
            <button
              class=""
              @click="addImage"
              @change="loadAvatar"
              :disabled="sending"
            >
              {{ $gettext('cambia avatar') }}
            </button>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>
      <hr />
      <div class="button">
        <button class="" @click="aggiornaProfilo" :disabled="sending">
          {{ $gettext('Aggiorna dati')}}
        </button>
      </div>
      <div class="sendMsg" v-html="sendMsg"></div>
    </div>
    <div v-if="step == 2" class="container">
      <h1>{{ $gettext('Aggiornamento dati effettuato') }}</h1>
      <br>
      <h2>{{ $gettext('Per visualizzare le tue nuove impostazioni è necessario effettuare nuovamente il login.') }}</h2>
    </div>
    <div v-if="step == 3" class="container">
      <h1>{{ $gettext('Errore nell\'aggiornamento') }}</h1>
      <div>
        {{ $gettext('Ci dispiace ma non è stato possibile aggiornare il tuo profilo. Per favore riprova più tardi.') }}
      </div>
    </div>
  </div>
</template>

<script>
import customInput from '@/components/shared/custom-input';
import customTextarea from '@/components/shared/custom-textarea';
import myMixin from '@/components/_mixin/myMixin.js';

export default {
  name: 'profileChange',
  components: { customTextarea, customInput },
  mixins: [myMixin],
  data() {
    return {
      step: 1,
      noLogged: true,
      dati: {},
      avatarSrc: '',
      avatarItem: '',
      avatarIsChanged: false,
      sending: false,
      sendMsg: '',
      language: 'it'
    };
  },
  methods: {
    addImage() {
      if (this.sending) return;
      const elem = document.getElementById('input_img');
      elem.click();
    },
    loadImage(event) {
      this.avatarItem = event.target.files[0];
      this.avatarSrc = URL.createObjectURL(event.target.files[0]);
      this.avatarIsChanged = true;
    },
    async aggiornaProfilo() {
      if (this.sending) return;
      this.sending = true;
      this.sendMsg = '';
      let _response = {};
      const params = {};
      //Carico l'immagine dell'avatar nei media.
      if (this.avatarIsChanged) {
        try {
          this.sendMsg = 'Invio immagine avatar';
          _response = await this.sendImage(this.avatarItem);

          params.avatar = _response.data.id;
          this.sendMsg = '';
        } catch (error) {
          console.log(error);
        }
      } else {
        params.avatar = -1; //Indica nel backoffice che non è cambiato.
      }

      params.first_name = this.dati.first_name;
      params.last_name = this.dati.last_name;
      params.bio = this.dati.bio;
      params.language = this.dati.language;

      this.sendMsg = 'Invio dati';

      let config = {
        headers: {
          Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
        },
      };
      try {
        _response = await this.axios.post(this.api.getMe, params, config);
        this.step = 2;
      } catch (error) {
        console.log(error);
        this.step = 3;
      }
      this.sending = false;
    },
    async caricaDatiProfilo() {
      try {
        const response = await this.axios.get(this.api.getMe, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters['auth/getToken'],
          },
        });
        console.log(response.data);
        this.$store.commit('auth/setDataProfile', response.data);
        this.dati = this.$store.getters['auth/getDataProfile'];
        console.log("this dati");
        console.log(this.dati);
        this.avatarSrc = response.data.avatar;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.noLogged = !this.$store.getters['auth/isLoggedIn'];
    this.caricaDatiProfilo();
  },
  created() {},
};
</script>

<style scoped>
#input_img {
  display: none;
}
#avatar_div {
  text-align: left;
  width: 80%;

  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

#img {
  float: left;
  margin-right: 20px;
  margin-top: 10px;
  border-radius: 50px;
  width: 96px;
  height: 96px;
  max-width: 96px;
  max-height: 96px;
  border-radius: 96px;
  background-size: cover;
}
#img img {
  max-width: 96px;
  max-height: 96px;
}
#avatar_div .button {
  float: left;
}

button:disabled {
  opacity: 0.4;
}

.container {
  padding-top: 30px;
}
</style>
